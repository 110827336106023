import React, { useContext, useState, useEffect, useRef } from 'react';
import { UserContext } from '../../contexts/context-layout-user';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import { downloadZip } from '../../actions/download'
import ModalMitigationActions from '../../layouts/layout-user/modal-mitigation-actions';
import ModalEditeCase from '../../layouts/layout-user/modal-edit-case';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import LinearProgress from '@mui/material/LinearProgress';
import SnackbarAlert from '../../layouts/layout-user/snackbar-alert';
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from 'react-window';

const columns = [
    {
        id: 1,
        label: 'boardLevel',
        minWidth: "50px",
        align: 'left',
        width: '50px',
        textAlign: "center"
    },
    {
        id: 2,
        label: 'flexibility',
        minWidth: "70px",
        align: 'left',
        width: '15%',
        textAlign: "center"
    },
    {
        id: 3,
        label: 'contingency',
        minWidth: "250px",
        heigth: 10,
        align: 'left',
        width: '15%',
        textAlign: "center"
    },
    {
        id: 4,
        label: 'element',
        minWidth: "250px",
        heigth: 10,
        align: 'left',
        width: '15%',
        textAlign: "center"
    },
    {
        id: 5,
        label: <TaskAltIcon />,
        minWidth: "30px",
        align: 'left',
        width: '30px',
        textAlign: "center"
    },
    {
        id: 6,
        label: 'limit',
        minWidth: "50px",
        align: 'left',
        width: '100px',
        textAlign: "center"
    },
    {
        id: 7,
        label: 'criticality',
        minWidth: "100px",
        align: 'left',
        width: '100px',
        textAlign: "center"
    },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Item = ({ row, father, setDisplayButton, setListMonitored, listMonitored, compared, color }) => {
    const [checked, setChecked] = useState([]);
    row.constraints = row.constraints && typeof row.constraints === typeof [] ? row.constraints.sort(function (a, b) {



        if (a.ranking < b.ranking) { return 1; }
        if (a.ranking > b.ranking) { return -1; }
        return 0;
    }) : row.constraints
    const handleClickCB = (event, index, contingency, monitored, scenarios) => {
        let newObje = {};
        newObje.contingency = contingency;
        newObje.monitored = monitored;
        newObje.scenarios = scenarios;

        const newChecked = [...checked];
        newChecked[index] = !newChecked[index];
        setChecked(newChecked);

        const elementoExistente = listMonitored.find(
            elemento => elemento.contingency === newObje.contingency && elemento.monitored === newObje.monitored
        );

        if (elementoExistente) {
            const nuevosElementos = listMonitored.filter(
                elemento => elemento.contingency !== newObje.contingency || elemento.monitored !== newObje.monitored
            );
            setListMonitored(nuevosElementos);
        } else {
            setListMonitored([...listMonitored, newObje])
        }

    }

    useEffect(() => {
        if (row.constraints && row.constraints.length > 0) {
            setChecked(row.constraints.map(c => false))
        }
    }, [row])

    useEffect(() => {
        const trueValueExists = checked.some(valor => valor === true);
        if (trueValueExists) {
            setDisplayButton(true)
        } else {
            setDisplayButton(false)
        }
    }, [checked])

    const returnColor = (flexibility) => {
        let color = '';
        if (flexibility > 79) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility <= 79) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    return (
        <StyledTableRow role="checkbox" tabIndex={-1}>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }}>
                {row.level}
            </TableCell>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ background: returnColor(row.flexibility.toFixed(0)), borderRadius: "50%", width: 20, height: 20, marginRight: 10 }}></div>
                    {row.flexibility.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                </div>
            </TableCell>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }}>
                {row.constraints.length === 0 || !row.constraints ? (
                    <div style={{ height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        {row.constraints.contingency}
                    </div>
                ) : (

                    <>
                        {row.constraints.map((constraint, index) => (
                            constraint.contingency === '' ? (
                                <div key={index} style={{
                                    height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                                }}
                                >
                                    {constraint.contingency}
                                </div>
                            ) : (
                                <div key={index} style={{
                                    height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                                }}
                                >
                                    <ModalMitigationActions key={index} info={constraint.contingency} caseStudy={father} index={index} row={row.constraints[index]} />
                                </div>
                            )
                        ))}
                    </>
                )

                }

            </TableCell>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0, height: '74px' }}>
                {row.constraints.map((constraint, index) => (
                    <div key={index} style={{
                        height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                    }}
                    >
                        <ModalMitigationActions info={constraint.monitored} caseStudy={father} index={index} row={row.constraints[index]} />
                    </div>
                ))}
            </TableCell>

            <TableCell align='left' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0, height: '74px' }} id='celda-table'>
                {row.constraints.map((constraint, index) => (
                    <div key={index} style={{
                        height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
                    }}
                    >
                        <TaskAltIcon key={index} color={checked[index] ? 'primary' : 'inherit'}
                            onClick={(event) => handleClickCB(event, index, constraint.contingency, constraint.monitored, constraint.scenarios)}
                            checked={checked[index] ? checked[index] : false}
                            onChange={() => { }} inputProps={{ 'aria-label': 'controlled' }} scenarios={constraint.scenarios} />
                    </div>
                ))}

            </TableCell>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0, height: '74px' }}>
                {row.constraints.map((constraint, index) => (
                    <div key={index} style={{
                        height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
                        color: color && (typeof constraint.limit === 'number' && constraint.limit.toFixed(0) == 0
                            ||
                            (compared && (typeof constraint.limit === 'string' && !(constraint.limit.toString().includes('-') ||
                                constraint.limit.toString().includes('+')) || typeof constraint.limit === 'number'))) ? "#9c742d" : ''
                        , fontWeight: color && typeof constraint.limit === 'number' && constraint.limit.toFixed(0) == 0 ? 'bold' : ''
                    }}
                    >
                        {typeof constraint.limit === 'number' ? constraint.limit.toFixed(0) :
                            typeof constraint.limit === 'string' && constraint.limit.toString().includes('-') ? (
                                <>
                                    {Number(constraint.limit.split('-')[0]).toFixed(0)}
                                    <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                                </>
                            ) :
                                typeof constraint.limit === 'string' && constraint.limit.toString().includes('+') ? (
                                    <>
                                        {Number(constraint.limit.split('+')[0]).toFixed(0)}
                                        <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                                    </>
                                ) : '0'
                        }

                    </div>
                ))}
            </TableCell>

            <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0, height: '74px' }}>
                {row.constraints.map((constraint, index) => (
                    <div key={index} style={{
                        height: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        borderBottom: index === row.constraints.length - 1 ? "none" : "1px solid rgba(0, 0, 0, 0.12)",
                        color: color && (typeof constraint.ranking === 'number' && constraint.ranking.toFixed(6) === '0.000000' ||
                            (compared && (typeof constraint.ranking === 'string' && !(constraint.ranking.toString().includes('-') ||
                                constraint.ranking.toString().includes('+')) || typeof constraint.ranking === 'number'))) ? '#9c742d' : '',
                        fontWeight: color && typeof constraint.ranking === 'number' && constraint.ranking.toFixed(6) === '0.000000' ? 'bold' : ''
                    }}
                    >

                        {typeof constraint.ranking === 'number' ? constraint.ranking.toFixed(6) :
                            typeof constraint.ranking === 'string' && constraint.ranking.toString().includes('-') ? (
                                <>
                                    {Number(constraint.ranking.split('-')[0]).toFixed(6)}
                                    <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                                </>
                            ) :
                                typeof constraint.ranking === 'string' && constraint.ranking.toString().includes('+') ? (
                                    <>
                                        {Number(constraint.ranking.split('+')[0]).toFixed(6)}
                                        <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                                    </>
                                ) : "0"
                        }

                    </div>
                ))}
            </TableCell>
        </StyledTableRow>
    )
}

export default function LayersDatails(props) {

    const { layers, setLayers, changeRows, setChangeRows, casest, loaderCase, setLoaderCase } = useContext(UserContext);
    const params = useParams();
    const [caseCheck, setCaseCheck] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selected, setSelected] = React.useState(null);
    const [checkboxStates, setCheckboxStates] = React.useState(Array(5).fill(false));
    const [displayButton, setDisplayButton] = React.useState(false);
    const [listMonitored, setListMonitored] = useState([]);
    const [serverResponse, setServerResponse] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [father, setFather] = React.useState({});


    const [t, i18next] = useTranslation("global")

    const [checked, setChecked] = React.useState({});

    React.useEffect(() => {
        setLoader(false)
        setFather(layers)
        setChecked({})
        setListMonitored([])
        setDisplayButton(false)
        setServerResponse(null)
        setPage(0)
        setValue(0)
        setRows(layers && layers.caseResult &&
            layers.caseResult[0] && layers.caseResult[0].layers ?
            layers.caseResult[0].layers : [])
    }, [layers])
    React.useEffect(() => {
        setLoader(loaderCase)
    }, [loaderCase])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClick = (item) => {
        setSelected(item);
    }

    const itemStyle = (item) => {
        return {
            backgroundColor: selected === item ? '#f1f1f1' : 'white',
        }
    }

    const handleChangeCheckbox = (index) => {
        setCheckboxStates((prev) => {
            const newStates = [...prev];
            newStates[index] = !prev[index];
            return newStates;
        });
    };

    function retunrColor(flexibility) {
        let color = '';
        if (flexibility > 79) {
            color = 'rgb(0, 153, 255)';
        } else if (flexibility >= 40 && flexibility <= 79) {
            color = 'rgb(245, 169, 5)';
        } else {
            color = 'rgb(210, 119, 73)';
        }
        return color;
    }

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

    async function downloadZipDocument() {
        try {

            downloadZip({ files: listMonitored }).then(async (response) => {
                // convertir los datos binarios en un objeto Blob

                const fileName = `${father && father.caseResult && father.caseResult[0] && father.caseResult[0].caseName}.zip`;
                // crear una URL de objeto para descargar el archivo
                const blob = await response.blob()
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            })


        } catch (error) {
            console.error(error);
        }
    }
    /**
     * Row function virtualizing scroll
     */

    useEffect(() => {

        const trueValueExists = Object.values(checked).some(valor => valor === true);
        if (trueValueExists) {
            setDisplayButton(true)
        } else {
            setDisplayButton(false)
        }
    }, [checked])

    const Row = ({ index, style }) => {

        let color = father.color || false
        let compared = father.caseResult[0].comparation || false

        if (rows[value]) {
            rows[value].constraints = rows[value].constraints && typeof rows[value].constraints === typeof [] ? rows[value].constraints.sort(function (a, b) {
                if (a.ranking < b.ranking) { return 1; }
                if (a.ranking > b.ranking) { return -1; }
                return 0;
            }) : rows[value].constraints
        } else {
            rows[0].constraints = rows[0].constraints && typeof rows[0].constraints === typeof [] ? rows[0].constraints.sort(function (a, b) {
                if (a.ranking < b.ranking) { return 1; }
                if (a.ranking > b.ranking) { return -1; }
                return 0;
            }) : rows[0].constraints
        }

        let row = rows[value] ? rows[value].constraints[index] : rows[0].constraints[index];

        const cellStyle = {
            minWidth: "250px",
            width: "15%",
            textAlign: "center",
            padding: "8px",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
        };



        const handleClickCB = (event, index, contingency, monitored, scenarios) => {
            let newObje = {};
            newObje.contingency = contingency;
            newObje.monitored = monitored;
            newObje.scenarios = scenarios;

            const key = `${page}_${index}`;
            const newChecked = { ...checked, [key]: !checked[key] };

            setChecked(newChecked);

            const elementoExistente = listMonitored.find(
                elemento => elemento.contingency === newObje.contingency && elemento.monitored === newObje.monitored
            );


            if (elementoExistente) {
                const nuevosElementos = listMonitored.filter(
                    elemento => elemento.contingency !== newObje.contingency || elemento.monitored !== newObje.monitored
                );
                setListMonitored(nuevosElementos);
            } else {
                setListMonitored([...listMonitored, newObje])
            }

        }


        const returnColor = (flexibility) => {
            let color = '';
            if (flexibility > 79) {
                color = 'rgb(0, 153, 255)';
            } else if (flexibility >= 40 && flexibility <= 79) {
                color = 'rgb(245, 169, 5)';
            } else {
                color = 'rgb(210, 119, 73)';
            }
            return color;
        }

        if (index === 0) {
            const width = window.innerHeight < 768 && rows[value].constraints.length > 6 ? "99%" : "99.7%";
            return (
                <TableRow style={{...style, width }}>
                    {columns && columns.map((column, index) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                                minWidth: column.minWidth,
                                width: column.width,
                                textAlign: column.textAlign,
                                padding: "8px"
                            }}
                        >
                            <b key={index}>{typeof column.label === "string" ? t(`board.casesTable.${column.label}`) : column.label}</b>
                        </TableCell>
                    ))}
                </TableRow>
            )
        } else {
            const dataIndex = index - 1
            row = rows[value] ? rows[value].constraints[dataIndex] : rows[0].constraints[dataIndex];
            console.log("dataIndex", dataIndex)
            return (

                <TableRow style={{ ...style }}>

                    <td key={0} align="left" style={{
                        minWidth: "50px",
                        width: "50px",
                        textAlign: "center",
                        padding: "8px",
                        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                        fontSize: "0.875rem",
                        borderTop: "none",
                        fontSize: "0.875rem",
                        borderBottom: dataIndex === rows[value].constraints.length - 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "none"
                    }}>
                        {dataIndex === Math.floor(rows[value].constraints.length / 2) ? rows[value]["level"] : null}
                    </td>
                    <td key={5} align="left" style={{
                        minWidth: "70px",
                        width: "15%",
                        textAlign: "center",
                        padding: "8px",
                        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        borderTop: "none",
                        borderBottom: dataIndex === rows[value].constraints.length - 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "none"
                    }}>
                        {dataIndex === Math.floor(rows[value].constraints.length / 2) ?
                            (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: "0.875rem" }}>
                                    <div style={{ background: returnColor(rows[value].flexibility.toFixed(0)), borderRadius: "50%", width: 20, height: 20, marginRight: 10 }}></div>
                                    {rows[value].flexibility.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                                </div>
                            ) : null}
                    </td>
                    <td key={1} align="left" style={cellStyle}>
                        {rows[value].constraints.length === 0 || !rows[value].constraints ? (
                            <div style={{ height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                {rows[value].constraints.contingency}
                            </div>
                        ) : (

                            <>
                                {row.contingency === '' ? (
                                    <div key={dataIndex} style={{
                                        height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                    >
                                        {row.contingency}
                                    </div>
                                ) : (
                                    <div key={dataIndex} style={{
                                        height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                    }}
                                    >
                                        <ModalMitigationActions key={dataIndex} info={row.contingency} caseStudy={father} index={dataIndex} row={rows[value].constraints[dataIndex]} />
                                    </div>
                                )}

                            </>
                        )

                        }
                    </td>
                    <td key={2} align="left" style={cellStyle}>
                        <div key={dataIndex} style={{
                            height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}
                        >
                            <ModalMitigationActions info={row.monitored} caseStudy={father} index={dataIndex} row={rows[value].constraints[dataIndex]} />
                        </div>
                    </td>
                    <td key={6} align="left" style={{
                        minWidth: "30px",
                        width: "30px",
                        textAlign: "center",
                        padding: "8px",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                    }}>
                        <div key={dataIndex} style={{
                            height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                        }}
                        >
                            <TaskAltIcon key={dataIndex} color={checked[`${page}_${dataIndex}`] ? 'primary' : 'inherit'}
                                onClick={(event) => handleClickCB(event, dataIndex, row.contingency, row.monitored, row.scenarios)}
                                checked={checked[`${page}_${dataIndex}`] ? checked[`${page}_${dataIndex}`] : false}
                                onChange={() => { }} inputProps={{ 'aria-label': 'controlled' }} scenarios={row.scenarios} />
                        </div>
                    </td>
                    <td key={3} align="left" style={{
                        minWidth: "50px",
                        width: "100px",
                        textAlign: "center",
                        padding: "8px",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                    }}>
                        <div key={dataIndex} style={{
                            height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                            fontSize: "0.875rem",
                            color: color && (typeof row.limit === 'number' && row.limit.toFixed(0) == 0
                                ||
                                (compared && (typeof row.limit === 'string' && !(row.limit.toString().includes('-') ||
                                    row.limit.toString().includes('+')) || typeof row.limit === 'number'))) ? "#9c742d" : ''
                            , fontWeight: color && typeof row.limit === 'number' && row.limit.toFixed(0) == 0 ? 'bold' : ''
                        }}

                        >
                            {typeof row.limit === 'number' ? row.limit.toFixed(0) :
                                typeof row.limit === 'string' && row.limit.toString().includes('-') ? (
                                    <>
                                        {Number(row.limit.split('-')[0]).toFixed(0)}
                                        <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                                    </>
                                ) :
                                    typeof row.limit === 'string' && row.limit.toString().includes('+') ? (
                                        <>
                                            {Number(row.limit.split('+')[0]).toFixed(0)}
                                            <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                                        </>
                                    ) : '0'
                            }

                        </div>
                    </td>
                    <td key={4} align="left" style={{
                        minWidth: "100px",
                        width: "100px",
                        textAlign: "center",
                        padding: "8px",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                    }}>
                        <div key={dataIndex} style={{
                            height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                            fontSize: "0.875rem",
                            color: color && (typeof row.ranking === 'number' && row.ranking.toFixed(6) === '0.000000' ||
                                (compared && (typeof row.ranking === 'string' && !(row.ranking.toString().includes('-') ||
                                    row.ranking.toString().includes('+')) || typeof row.ranking === 'number'))) ? '#9c742d' : '',
                            fontWeight: color && typeof row.ranking === 'number' && row.ranking.toFixed(6) === '0.000000' ? 'bold' : ''
                        }}
                        >

                            {typeof row.ranking === 'number' ? row.ranking.toFixed(6) :
                                typeof row.ranking === 'string' && row.ranking.toString().includes('-') ? (
                                    <>
                                        {Number(row.ranking.split('-')[0]).toFixed(6)}
                                        <span style={{ color: 'red', marginLeft: 5, fontWeight: 700 }}>Out</span>
                                    </>
                                ) :
                                    typeof row.ranking === 'string' && row.ranking.toString().includes('+') ? (
                                        <>
                                            {Number(row.ranking.split('+')[0]).toFixed(6)}
                                            <span style={{ color: 'green', marginLeft: 5, fontWeight: 700 }}>In</span>
                                        </>
                                    ) : "0"
                            }

                        </div>
                    </td>
                </TableRow>
            );
        }
    };

    /**
     * Headers
     */

    const Header = ({ index, style }) => {

        const cellStyle = {
            minWidth: "250px",
            width: "15%",
            textAlign: "center",
            padding: "8px",
            borderRight: "1px solid rgba(0, 0, 0, 0.12)"
        };


        return (
            <TableRow style={style}>
                {columns && columns.map((column, index) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                            minWidth: column.minWidth,
                            width: column.width,
                            textAlign: column.textAlign,
                            padding: "8px"
                        }}
                    >
                        <b key={index}>{typeof column.label === "string" ? t(`board.casesTable.${column.label}`) : column.label}</b>
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const [tableContainerHeight, setTableContainerHeight] = useState(window.innerHeight - 250);

    useEffect(() => {
        const updateViewportHeight = () => {
            setTableContainerHeight(window.innerHeight - 250);
        };

        updateViewportHeight();

        window.addEventListener('resize', updateViewportHeight);

        return () => {
            window.removeEventListener('resize', updateViewportHeight);
        };
    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {loader ? <LinearProgress /> :
                father && father.caseResult ? father.caseResult.length > 0 && father.process && father.process === true ?
                    (<>El caso aún se está procesando... Por favor entre más tarde</>) : father.caseResult[0].layers.length > 0 ? (<>
                        <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }}>
                            <TableContainer sx={{ height: "100%" }} id="table-container">
                                <Table aria-label="sticky table" stickyHeader >
                                    <TableBody >
                                        <TableRow>
                                            <TableCell align="center" colSpan={2} style={{ padding: 0 }}>
                                                <div style={
                                                    {
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center'
                                                    }
                                                }
                                                >
                                                    <div>
                                                        <Typography variant='h6'>
                                                            {father && father.caseResult[0] && father.caseResult[0].caseName
                                                                && father.caseResult[0].caseName.length > 40 ?
                                                                `${father && father.caseResult[0].caseName.substr(0, 40)}...` : father.caseResult[0].caseName}
                                                        </Typography>
                                                    </div>

                                                    <div>
                                                        <ModalEditeCase idCase={father._id} name={father && father.caseResult[0] && father.caseResult[0].caseName} setServerResponse={setServerResponse} infoCases={father} />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" colSpan={2} style={{ padding: 0 }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <div>
                                                        <Typography variant='h6'>
                                                            {formatDate(father.creationDate)}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" colSpan={3} style={{ padding: 0 }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {listMonitored.length > 0 &&
                                                        <Button variant="outlined" color='inherit' startIcon={<DownloadIcon />} size="small" onClick={downloadZipDocument}
                                                        >
                                                            Descargar
                                                        </Button>
                                                    }
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody >
                                </Table>
                                <Table aria-label="sticky table" stickyHeader >
                                    <TableBody >
                                        <TableRow>

                                            <TableCell
                                                align="center"
                                                style={{ background: "white", color: "black", width: '60%', padding: 0 }}
                                            >
                                                <b>{t("board.casesTable.restriction")}</b>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody >
                                </Table >
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={value.toString()}>
                                        <Box sx={{ width: '100%' }}>
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                textColor="secondary"
                                                aria-label="secondary tabs example"
                                                style={
                                                    {
                                                        minHeight: "18px"
                                                    }
                                                }
                                                TabIndicatorProps={{
                                                    style: {
                                                        backgroundColor: '#400190',
                                                    },
                                                }}
                                            >
                                                {rows.map((row, index) => (
                                                    <Tab
                                                        key={index}
                                                        value={index}
                                                        label={`${t("board.casesTable.boardLevel")} ${row.level}`}
                                                        sx={{
                                                            '&.Mui-selected': {
                                                                textTransform: 'none',
                                                                color: 'white',
                                                                borderTopLeftRadius: '8px',
                                                                borderTopRightRadius: '8px',
                                                                height: "2rem",
                                                                minHeight: "18px",
                                                                backgroundColor: '#400190',
                                                            },
                                                            textTransform: 'none',
                                                            color: 'white',
                                                            height: "2rem",
                                                            minHeight: "18px",
                                                            borderTopLeftRadius: '8px',
                                                            borderTopRightRadius: '8px',
                                                            backgroundColor: '#711fd9',
                                                        }}
                                                    />
                                                ))}
                                            </Tabs>
                                        </Box>
                                    </TabContext>
                                </Box>
                                <Table aria-label="sticky table" stickyHeader >

                                    {
                                        !rows[value].constraints || rows[value].constraints.length === 0 ? (
                                            <StyledTableRow role="checkbox" tabIndex={-1} key={rows[value].code} style={{ height: 60 }} >
                                                <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }}>
                                                    {rows[value].level}
                                                </TableCell>

                                                <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }} >
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <div style={{
                                                            background: retunrColor(rows[value].flexibility.toFixed(0)), borderRadius: "50%",
                                                            width: 20, height: 20, marginRight: 10
                                                        }}>

                                                        </div>
                                                        {
                                                            rows[value].flexibility.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 })
                                                        }
                                                    </div>
                                                </TableCell>

                                                <TableCell align='center' style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12)", padding: 0 }} colSpan={5}>
                                                    {rows[value].flexibility === 0
                                                        ? 'No hay región segura'
                                                        : 'No existen restricciones en este nivel'}
                                                </TableCell>

                                            </StyledTableRow>
                                        ) : (
                                            <>
                                                {Object.keys(father).length > 0 && (
                                                    <>
                                                        <List
                                                            height={tableContainerHeight - 10}
                                                            itemCount={rows[value].constraints.length + 1}
                                                            itemSize={52}
                                                            width={'100%'}
                                                        >
                                                            {Row}
                                                        </List>
                                                    </>
                                                )}
                                            </>
                                        )}

                                </Table>
                            </TableContainer>
                        </Paper>

                        <SnackbarAlert
                            vertical="top"
                            horizontal="center"
                            serverResponse={serverResponse}
                            message={'Se ha actualizado el caso correctamente'}
                            severity={'success'}
                        />
                    </>) : (<>No se encuentran contingencias en este caso</>) : <></>}
        </>

    );

}